<template>
  <div class="home_vue_comp">
    <v-main>
      <v-container class="training-table black--text pa-0" id="home-container">
        <v-row class="mt-5">
          <v-col cols="12" class="log-header d-flex justify-space-between">
            <h4>Training Reports</h4>
            <v-btn to="/trainingReport_add">Add</v-btn>
            <v-btn to="/data_table">data table</v-btn>
          </v-col>
        </v-row>
        <router-view />
        <v-row id="data_table" no-gutters class="home_vue_comp section1 mt-2">
          <v-col
            lg="12"
            md="12"
            sm="12"
            class="alice d-flex flex-column justify-start"
          >
            <v-data-table
              class="pb-8 elevation-1 border-thin"
              :headers="headers"
              :items="logs"
              :items-per-page="5"
            ></v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";

/* 0 media q */

@media (min-width: 0px) {
  #home-container {
    width: 95%;
    height: auto;
  }

  .alice {
    color: aliceblue;
  }

  .log-header .v-btn {
    background-color: #fc5c5c;
  }

  .section1 h1 {
    text-shadow: 2px 2px 4px #000000;
  }

  .training-table .v-data-table {
    border: 1px solid darkslateblue;
  }

  .training-table .v-btn__content {
    visibility: hidden !important;
  }
}

/* 768 media q */
@media (min-width: 768px) {
  .section1 {
    justify-content: center;
    width: 100%;
  }
}

/* 1025 media q */
@media (min-width: 1025px) {
  .section1 {
    justify-content: flex-start;
    width: 100%;
  }
}

/* 2048 media q */
@media (min-width: 2048px) {
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";
import banner from "./banner.vue";

export default {
  name: "Home",
  components: {
    banner,
  },
  mixins: [bus_common],
  data() {
    return {
      headers: [
        { text: "Officer", align: "center", sortable: true, value: "name" },
        {
          text: "Specialty",
          align: "center",
          sortable: true,
          value: "specialty",
        },
        { text: "Date", align: "center", sortable: true, value: "date" },
        { text: "Case#", align: "center", sortable: true, value: "casenum" },
        { text: "Time", align: "center", sortable: true, value: "time" },
      ],
      logs: [
        {
          name: "Smith",
          specialty: "Explosive Breaching AARs",
          date: "01/01/2024",
          casenum: "24-00023",
          time: "13:00",
        },
        {
          name: "Jones",
          specialty: "Sniper AARs",
          date: "01/01/2024",
          casenum: "24-00024",
          time: "09:00",
        },
        {
          name: "Barnes",
          specialty: "Ropes AArmRs",
          date: "01/01/2024",
          casenum: "24-00025",
          time: "23:00",
        },
      ],

      lst_officer_names: [
        "Autry",
        "Baehr",
        "Butler",
        "Chang",
        "Christensen, B",
        "Clifford",
        "Donigan",
        "Dougherty",
        "Duralde",
        "Espinoza, A",
        "Hakin",
        "Hanna",
        "Hendrix",
        "Hogan",
        "Hoyt",
        "Kent",
        "Kerfoot",
        "Lancaster",
        "Lopey",
        "Mathiowetz",
        "Meseberg",
        "Perham, J",
        "Robles",
        "Rousseau",
        "Schaur",
        "Schmidt",
        "Smith",
        "Sotelo",
        "Struffert",
        "Tom",
        "Tone",
        "Waasdorp",
        "Williams",
        "Ziese",
      ],

      lst_specialty: [
        "Explosive Breaching AARs",
        "Sniper AARs",
        "Ropes AArmRs",
        "Team training AARs",
        "Mission AARs",
      ],
      right: null,
      search: "",
    };
  },
  watch: {
    "$store.state.user": function () {
      //keep this
      let self = this;
      this.init();
    },
  },
  methods: {
    /* sendRequest(rowData) {
      console.log(rowData);
    }, */
    sendRequest() {
      alert("This button does nothing yet.");
    },
    message1() {
      alert("This button does nothing yet.");
    },

    message2() {
      alert("when clicked this ");
    },

    init(self) {
      this.show_loader(false);
    },
  },
  mounted() {
    var self = this;
    this.$nextTick(() => {
      this.init(self);
    });
  },
};
</script>
